<template>
  <v-dialog :value="value" persistent width="500">
    <v-card color="#fafafa">
      <v-toolbar dark flat color="#1C3144">
        <v-btn icon @click="$emit('update:value', false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Invoice Number: {{selectedBatch.invoice_number}}</v-toolbar-title>
      </v-toolbar>
      <v-container class="ma-0 pa-6">
        <v-row dense>
          <v-autocomplete auto-select-first outlined dense background-color="#fff" label="Modify Vendor"
            :items="manufacturers" item-text="description" item-value="id" :menu-props="{ maxHeight: 215 }" v-model="manufacturer_id"/>
        </v-row>
      </v-container>
      <v-divider />
      <v-toolbar flat color="#fafafa">
        <v-spacer />
        <v-btn :disabled="!selectedVendor" color="green" class="white--text" height="40" @click="updateVendor">
          Update<v-icon right>mdi-update</v-icon>
        </v-btn>
      </v-toolbar>
    </v-card>
  </v-dialog>
</template>

<script>
import { notification } from '@/mixins/notification'

export default {
  name: 'InvoiceVendorUpdate',

  mixins: [notification],

  props: ['value', 'selectedBatch', 'getBatches'],

  data () {
    return {
      manufacturer_id: null,
      manufacturers: []
    }
  },

  computed: {
    selectedVendor () {
      return this.manufacturer_id
    }
  },

  watch: {
    value: {
      immediate: true,
      handler (value) {
        if (value) {
          this.getManufacturers()
          this.manufacturer_id = null
        }
      }
    }
  },

  methods: {
    async getManufacturers () {
      this.$contracts.getManufacturers()
        .then(response => {
          this.manufacturers = response
        })
    },

    async updateVendor () {
      await this.$invoicing.updateVendor(this.selectedBatch.batch_number, this.selectedBatch.invoice_number, this.manufacturer_id)
        .then(() => {
          this.notify('success', 'Vendor Successfully Updated')
          this.$emit('update:value', false)
        }).catch(() => {
          this.notify('error', 'Failed to Update Vendor')
        })
      this.getBatches(this.toggle)
    }
  }
}
</script>
