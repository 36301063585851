var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    { attrs: { value: _vm.value, persistent: "", width: "500" } },
    [
      _c(
        "v-card",
        { attrs: { color: "#fafafa" } },
        [
          _c(
            "v-toolbar",
            { attrs: { dark: "", flat: "", color: "#1C3144" } },
            [
              _c(
                "v-btn",
                {
                  attrs: { icon: "" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("update:value", false)
                    }
                  }
                },
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              ),
              _c("v-toolbar-title", [
                _vm._v(
                  "Invoice Number: " + _vm._s(_vm.selectedBatch.invoice_number)
                )
              ])
            ],
            1
          ),
          _c(
            "v-container",
            { staticClass: "ma-0 pa-6" },
            [
              _c(
                "v-row",
                { attrs: { dense: "" } },
                [
                  _c("v-autocomplete", {
                    attrs: {
                      "auto-select-first": "",
                      outlined: "",
                      dense: "",
                      "background-color": "#fff",
                      label: "Modify Vendor",
                      items: _vm.manufacturers,
                      "item-text": "description",
                      "item-value": "id",
                      "menu-props": { maxHeight: 215 }
                    },
                    model: {
                      value: _vm.manufacturer_id,
                      callback: function($$v) {
                        _vm.manufacturer_id = $$v
                      },
                      expression: "manufacturer_id"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-toolbar",
            { attrs: { flat: "", color: "#fafafa" } },
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  staticClass: "white--text",
                  attrs: {
                    disabled: !_vm.selectedVendor,
                    color: "green",
                    height: "40"
                  },
                  on: { click: _vm.updateVendor }
                },
                [
                  _vm._v(" Update"),
                  _c("v-icon", { attrs: { right: "" } }, [_vm._v("mdi-update")])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }